import React, {useMemo, useState} from 'react';
import {
    SidebarRightBenchtop,
    SidebarRightProducts,
    SidebarRightRoom,
    SidebarRightSundries,
    PromptButton,
} from 'shared';
import {Loader, Icon, pdfOptionsOrder} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {useJobContext, useAppContext} from 'contexts';
import {useJobSubmitHandler, PDFMenu} from 'hooks';
import {Dropdown} from 'react-bootstrap';
import SideBarRightFooter from 'shared/components/Header/SideBarRightFooter';
import OutsideClickHandler from 'shared/components/Header/OutsideClickHandler';
import {SidebarRightBenchtopModule} from 'shared/components/Header/SidebarRightBenchtopModule';
import {SecondaryButton} from 'shared/components/SecondaryButton';
import {
    VariationResetButton,
    useVariationResetButton,
} from 'shared/components/Header/VariationResetButton';
import {JOB_STATUS} from 'components/customer/Dashboard/LastJobButton';
import useRequestedDeliveryPrompt from 'shared/helpers/useRequestedDeliveryPrompt';
import {RequestedDeliveryDateFrequencyEnum} from 'components/manufacturer/Preferences/entity/Preferences';

export const CartContent = () => {
    const {job, isPageDirty, actionButtonsEnabled, expiredJobPrice} =
        useJobContext();
    const {userProfile} = useAppContext();
    const {jobSubmitHandler, loader, dialog, showJobDialog} =
        useJobSubmitHandler();
    const [showPdfDropdown, setShowPdfDropdown] = useState(false);
    const {noVariations, notRemovableExtraVariatons, extraVariation} =
        useVariationResetButton();
    const {isDateExpired, requestedDeliveryPromptConfig} =
        useRequestedDeliveryPrompt(
            job.requestedDeliveryDate,
            userProfile?.requestedDeliveryDateFrequency ===
                RequestedDeliveryDateFrequencyEnum.NEVER,
            jobSubmitHandler
        );

    const pdfDropDown = useMemo(() => {
        return {
            dropdown: [
                {
                    name: PDFMenu.JOB_DETAILS_PDF,
                    show:
                        userProfile.hasOwnProperty(
                            'showJobPropertiesTablePdf'
                        ) && userProfile.showJobPropertiesTablePdf
                            ? true
                            : false,
                    link: `/api/job/${job.id}/pdf/details`,
                    order: pdfOptionsOrder(
                        PDFMenu,
                        PDFMenu.JOB_DETAILS_PDF,
                        userProfile
                    ),
                },
                {
                    name: PDFMenu.ORDER_ACKNOWLEDGEMENT_PDF,
                    show:
                        userProfile.hasOwnProperty(
                            'showOrderAcknowledgementPdf'
                        ) && userProfile.showOrderAcknowledgementPdf
                            ? true
                            : false,
                    link: `/quotePdf.php?jobId=${job.id}`,
                    order: pdfOptionsOrder(
                        PDFMenu,
                        PDFMenu.ORDER_ACKNOWLEDGEMENT_PDF,
                        userProfile
                    ),
                },
                {
                    name: PDFMenu.JOB_TOPDOWN_LAYOUT,
                    show:
                        job.hasOwnProperty('MaxLayoutStatus') &&
                        job.MaxLayoutStatus >= 2,
                    link: `/jobTopDownLayoutPDF.php?jobId=${job.id}`,
                    order: 4,
                },
            ],
        };
    }, [job, userProfile]);

    const onOutsideClickFunc = () => {
        setShowPdfDropdown(false);
    };

    const submitButtonText = useMemo(() => {
        let buttonText = 'Submit Job';
        if (job.status === JOB_STATUS.JOB_PENDING_CONFIRMATION) {
            if (!noVariations && !extraVariation && job.freightCost == 0) {
                buttonText = 'Confirm Variation Cost';
            } else if (
                noVariations &&
                !extraVariation &&
                job.freightCost == 0
            ) {
                buttonText = 'Approve without Variations';
            } else {
                buttonText = 'Approve Job';
            }
        }

        return buttonText;
    }, [
        job.status,
        job.isDispatchMethodChanged,
        noVariations,
        notRemovableExtraVariatons,
        extraVariation,
    ]);

    const showReqDeliveryPrompt = () => {
        showJobDialog(requestedDeliveryPromptConfig);
    };

    return (
        <>
            <Loader loader={loader}>
                <section className="sidebar-content">
                    <div className="detail-export">
                        <div>
                            <div className="jobId">
                                {`Job #${job.displayId ? job.displayId : ''}`}
                            </div>
                            <div className="jobTitle">{job.name}</div>
                        </div>

                        <div>
                            <OutsideClickHandler
                                onOutsideClick={onOutsideClickFunc}>
                                <Dropdown
                                    className="table-option-dropdown pdfDropDownCart"
                                    show={showPdfDropdown}
                                    drop="down"
                                    onBlur={(e) => {
                                        // only hide if click outside of dropdown
                                        const clickNode =
                                            e.relatedTarget &&
                                            e.relatedTarget.nodeName == 'A'
                                                ? e.relatedTarget
                                                : e.target;
                                        clickNode.click();
                                        setShowPdfDropdown(false);
                                    }}>
                                    <Dropdown.Toggle
                                        variant="link"
                                        className="button-blue btn-sm"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowPdfDropdown(
                                                !showPdfDropdown
                                            );
                                        }}>
                                        <Icon iconName="Button-Create-PDF.svg" />
                                        <span>PDF</span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu renderOnMount={true}>
                                        {Object.keys(pdfDropDown).length > 0
                                            ? pdfDropDown.dropdown.map(
                                                  (option, index) => {
                                                      if (
                                                          option.hasOwnProperty(
                                                              'show'
                                                          )
                                                      ) {
                                                          if (
                                                              typeof option.show ===
                                                                  'boolean' &&
                                                              !option.show
                                                          )
                                                              return (
                                                                  <div
                                                                      key={
                                                                          index
                                                                      }
                                                                  />
                                                              );
                                                      }
                                                      return (
                                                          <Dropdown.Item
                                                              href={option.link}
                                                              target="_blank"
                                                              key={index}>
                                                              {option.name}
                                                          </Dropdown.Item>
                                                      );
                                                  }
                                              )
                                            : null}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </OutsideClickHandler>
                        </div>
                    </div>
                    {typeof job.id !== 'undefined' &&
                    actionButtonsEnabled &&
                    job.status != 5 ? (
                        <SecondaryButton
                            className="mt-3 p-1"
                            to={`/v2/job/${job.displayId}`}
                            $fullWidth>
                            <Icon iconName="Button-Edit.svg" />
                            <span>Edit Job Details</span>
                        </SecondaryButton>
                    ) : null}

                    {/* Right sidebar room table contents*/}
                    <SidebarRightRoom />

                    {/* Right sidebar product table contents*/}
                    <SidebarRightProducts />

                    {/* Right sidebar Benchtop table contents*/}
                    <SidebarRightBenchtop />

                    {/* Right sidebar Benchtop Module table contents*/}
                    <SidebarRightBenchtopModule />

                    {/* Right sidebar Sundries table contents*/}
                    <SidebarRightSundries />
                </section>
                <section className="sidebar-footer">
                    <SideBarRightFooter />
                    {userProfile.inActiveManufacturer == 0 &&
                    (job.status == 0 || job.status == 5) &&
                    !expiredJobPrice ? (
                        <PromptButton
                            disabled={
                                Object.keys(job).length == 0 ||
                                job.totalProductCount == 0
                            }
                            iconName="Button-Submit.svg"
                            action={
                                isDateExpired
                                    ? showReqDeliveryPrompt
                                    : jobSubmitHandler
                            }
                            showConfirmation={isPageDirty.length > 0}
                            className="button-blue btn-sm">
                            {submitButtonText}
                        </PromptButton>
                    ) : null}

                    <VariationResetButton />
                </section>
            </Loader>

            {dialog}
        </>
    );
};

export const Cart = () => {
    if (isDeviceSmall()) {
        return (
            <section className="mobile-cart sidebar sidebar-right">
                <CartContent />
            </section>
        );
    } else {
        return <></>;
    }
};
